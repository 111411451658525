<template>
  <div id='comment'>
    <el-card>
      <el-row :gutter="20" class="operate">
        <el-col :span="4">
          <el-select v-model="selectType" placeholder="评论类型" filterable clearable>
            <el-option v-for="(item, index) in typeList" :key="index" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-input v-model="inputName" placeholder="关联外键名称" clearable></el-input>
        </el-col>
        <el-col :span="4">
          <el-input v-model="inputContent" placeholder="评论内容" clearable></el-input>
        </el-col>
        <el-button @click="search" icon="el-icon-search" type="primary"></el-button>
        <el-button @click="deleteItems" type="danger" icon="el-icon-delete"></el-button>
      </el-row>
      <el-table v-loading="loading" :data="tableData" style="width: 100%" stripe border>
        <el-table-column fixed="left" type="selection" width="50"></el-table-column>
        <el-table-column fixed="left" prop="id" label="ID" width="100"></el-table-column>
        <el-table-column prop="fk_type_name" label="类型" width="100"></el-table-column>
        <el-table-column prop="fk_id" label="外键ID" width="100"></el-table-column>
        <el-table-column prop="username" label="用户名" width="200"></el-table-column>
        <el-table-column prop="os" label="操作系统"></el-table-column>
        <el-table-column prop="browser" label="浏览器"></el-table-column>
        <el-table-column prop="country" label="国家"></el-table-column>
        <el-table-column prop="city" label="城市"></el-table-column>
        <el-table-column prop="client_ip" label="IP"></el-table-column>
        <el-table-column label="状态" width="150">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status" @change="changeStatus(scope.row)" :active-value="1" :inactive-value="0"></el-switch><span class="status_text">{{scope.row.status | statusText}}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="150">
          <template #default="scope">
            <el-button @click="viewComment(scope.row.content)" type="primary" size="mini">查看</el-button>
            <el-button @click="deleteItem(scope.row.id)" type="danger" size="mini">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="params.page" :page-sizes="[5, 10, 20, 50, 100, 200]" :page-size="params.page_size" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>

      <!-- 查看弹窗 -->
      <el-dialog title="详情" :visible.sync="checkItem" center class="check_dialog">
        <div class="check_data" v-html="content"></div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="checkItem = false">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
  import {getCommentList, editCommentItem, deleteCommentItem, deleteComments} from '@api';
  export default {
    data() {
      return {}
    },
    components: {},
    data() {
        return {
            params: { // 请求分页参数
                ids: [],
                page: 1,
                page_size: 10
            },
            typeList: [{name: '文章', value: 1}, {name: '书籍', value: 2}, {name: '留言', value: 3}],
            tableData: [], // 表格数据
            loading: true, // 表格加载等待
            total: 0, // 数据总条数
            selectType: '', // 搜索关联外键类型,
            inputName: '', // 搜索关联外键名称
            inputContent: '', // 搜索评论内容
            content: '', // 当前评论详情
            checkItem: false, // 是否查看评论详情
        }
    },
    created() {
        this.getData(this.params);
    },
    filters: {
        statusText(status) {
            if (status > 0) {
                return '已审核';
            }
            return '未审核';
        }
    },
    methods:{
        search() { // 搜索
            const {inputName, selectType, inputContent} = this;
            this.params.name = inputName;
            this.params.fk_type = selectType;
            this.params.content = inputContent;
            this.getData(this.params);
        },
        viewComment(content) {
            this.checkItem = true;
            this.content = content;
        },
        deleteItem(id) { // 删除单挑数据
            this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                deleteCommentItem(id).then(() => {
                    this.$message.success('删除成功!');
                    this.getData(this.params);
                });
            }).catch(() => this.$message.info('已取消删除'));
        },
        deleteItems() {
            this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.multipleSelection.forEach(item => {
                    this.params.ids.push(item.id);
                });
                deleteComments(this.params).then(() => {
                    this.params.ids = [];
                    this.$message.success('删除成功!');
                    this.getData(this.params);
                });
            }).catch(() => this.$message.info('已取消删除'));
        },
        changeStatus(item) {
            item.fields = ["status"];
            item.status = item.status ? 1 : 0;
            editCommentItem(item.id, item).then(() => {
                this.$message.success('修改成功！');
                this.getData(this.params);
            });
        },
        handleCurrentChange(val) { // 获取页数
            this.params.page = val;
            this.getData(this.params);
        },
        handleSizeChange(val) { // 获取每页条数
            this.params.page_size = val;
            this.getData(this.params);
        },
        getData(params) {
            this.loading = true;
            getCommentList(params).then(res => {
                this.loading = false;
                const {data, count} = res.data;
                this.total = count;
                this.tableData = data;
            }).catch(() => {
                this.loading = false;
            })
        }
    },
  }
</script>

<style lang='less' scoped>

</style>